import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchCategoryRequest,
  updateCategoryRequest,
  addCategoryRequest,
} from "../../redux_store/actions/categoryActions";
import {
  fetchSubCategoryRequest,
  updateSubCategoryRequest,
  addSubCategoryRequest,
} from "../../redux_store/actions/subCategoryActions";
import {
  fetchNavigationMenuRequest,
  updateNavigationMenuRequest,
  addNavigationMenuRequest,
} from "../../redux_store/actions/navigationMenuActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddNavigationMenu = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { navigationMenu, loading, error } = useSelector(
    (state) => state.navigationMenu
  );

  const { category, cat_loading, cat_error } = useSelector(
    (state) => state.category
  );

  const { subCategory, sub_cat_loading, sub_cat_error } = useSelector(
    (state) => state.subCategory
  );

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Url: "",
    Order: "",
    Type: "Internal Link",
    HomeScreen: false,
    DisplayAs: "DropDown Menu",
    MenuProducts: [],
    MenuCategories: [],
    MenuSubCategories: [],
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is Required"),
    Url: Yup.string(),
    HomeScreen: Yup.boolean(),
    Order: Yup.number().integer().nullable(),
    Type: Yup.string(),
    DisplayAs: Yup.string(),
    MenuProducts: Yup.array(),
    MenuCategories: Yup.array(),
    MenuSubCategories: Yup.array(),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateNavigationMenuRequest(values, id, navigate))
      : // console.log(values)
        dispatch(addNavigationMenuRequest(values, navigate));
  };

  useEffect(() => {
    // fetching categories for dropdown
    dispatch(fetchCategoryRequest());

    // fetching subcategories for dropdown
    dispatch(fetchSubCategoryRequest());

    if (editMode) {
      dispatch(fetchNavigationMenuRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && navigationMenu && navigationMenu.length > 0) {
      const filteredNavigationMenu = navigationMenu[0];


      console.log(filteredNavigationMenu.menuSubCategories)

      setInitialValues({
        Name:
          filteredNavigationMenu.name !== null
            ? filteredNavigationMenu.name
            : "",
        Url:
          filteredNavigationMenu.url !== null ? filteredNavigationMenu.url : "",
        Order:
          filteredNavigationMenu.order !== null
            ? filteredNavigationMenu.order
            : null,
        Type:
          filteredNavigationMenu.type !== null
            ? filteredNavigationMenu.type
            : "",
        HomeScreen:
          filteredNavigationMenu.showOnHomeScreen !== null
            ? filteredNavigationMenu.showOnHomeScreen
            : false,
        DisplayAs:
          filteredNavigationMenu.displayAs !== null
            ? filteredNavigationMenu.displayAs
            : "",
        MenuProducts:
          filteredNavigationMenu.menuProducts !== null
            ? filteredNavigationMenu.menuProducts.map(product => product.productId)
            : [],
        MenuCategories:
          filteredNavigationMenu.menuCategories !== null
            ? filteredNavigationMenu.menuCategories.map(category => category.categoryId)
            : [],
        MenuSubCategories:
          filteredNavigationMenu.menuSubCategories !== null
            ? filteredNavigationMenu.menuSubCategories.map(subCategory => subCategory.subCategoryId)
            : [],
      });
    }
  }, [editMode, navigationMenu]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>
              {editMode && viewMode ? (
                <span>View Navigation Menu</span>
              ) : !viewMode && editMode ? (
                <span>Edit Navigation Menu</span>
              ) : (
                <span>Add Navigation Menu</span>
              )}
            </h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            className="form-control border-0 ps-2"
                            placeholder="Menu Name"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Type"
                          className="form-label fw-semibold"
                        >
                          Type
                        </label>
                        <Field
                          as="select"
                          id="Type"
                          name="Type"
                          className="form-select"
                          disabled={viewMode}
                        >
                          <option value="Internal Link">Internal Link</option>
                          <option value="External Link">External Link</option>
                          <option value="Product Collection">
                            Product Collection
                          </option>
                          <option value="Category Collection">
                            Category Collection
                          </option>
                          <option value="SubCategory Collection">
                            SubCategory Collection
                          </option>
                        </Field>
                        <ErrorMessage
                          name="Type"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    {(values.Type === "Internal Link" || values.Type === "External Link" ) && (
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            htmlFor="Url"
                            className="form-label fw-semibold"
                          >
                            URL
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-link fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Url"
                              name="Url"
                              className="form-control border-0 ps-2"
                              placeholder="URL"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Url"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div> )
                    }


                      {values.Type === "Product Collection" && (
                      <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MenuProducts"
                          className="form-label fw-semibold"
                        >
                          Menu Products
                        </label>
                        <Field
                          as="select"
                          id="MenuProducts"
                          name="MenuProducts"
                          multiple
                          className="form-select"
                          disabled={viewMode}
                        >
                          {subCategory.map((product) => (
                            <option key={product.id} value={product.id}>
                              {product.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="MenuProducts"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                      )}

                    {values.Type === "Category Collection" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MenuCategories"
                          className="form-label fw-semibold"
                        >
                          Menu Categories
                        </label>
                        <Field
                          as="select"
                          id="MenuCategories"
                          name="MenuCategories"
                          multiple
                          className="form-select"
                          disabled={viewMode}
                        >
                          {category.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="MenuCategories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    )}

                    {values.Type === "SubCategory Collection" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MenuSubCategories"
                          className="form-label fw-semibold"
                        >
                          Menu Subcategories
                        </label>
                        <Field
                          as="select"
                          id="MenuSubCategories"
                          name="MenuSubCategories"
                          multiple
                          className="form-select"
                          disabled={viewMode}
                        >
                          {subCategory.map((subcategory) => (
                            <option key={subcategory.id} value={subcategory.id}>
                              {subcategory.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="MenuSubCategories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    )}

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Order"
                          className="form-label fw-semibold"
                        >
                          Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="Order"
                            name="Order"
                            className="form-control border-0 ps-2"
                            placeholder="Order"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Order"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    {values.Type === "Product Collection" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="DisplayAs"
                          className="form-label fw-semibold"
                        >
                          Display As
                        </label>
                        <Field
                          as="select"
                          id="DisplayAs"
                          name="DisplayAs"
                          className="form-select"
                          disabled={viewMode}
                        >
                          <option value="DropDown Menu">DropDown Menu</option>
                          <option value="Product List Page">
                            Product List Page
                          </option>
                        </Field>
                        <ErrorMessage
                          name="DisplayAs"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    )}

                    {values.Type === "Product Collection" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="HomeScreen"
                            name="HomeScreen"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="HomeScreen"
                            className="fw-semibold form-check-label"
                          >
                            Show On Home Screen?
                          </label>
                        </div>
                        <ErrorMessage
                          name="HomeScreen"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    )}
                  </div>

                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        {!viewMode && (
                          <button
                            type="submit"
                            className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                          >
                            Save
                          </button>
                        )}
                        <Link
                          to="/navigationMenu"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNavigationMenu;
