import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_NAVIGATION_MENU_URL , ADD_NAVIGATION_MENU_URL, DELETE_NAVIGATION_MENU__URL, UPDATE_NAVIGATION_MENU__URL, MULTIPLE_NAVIGATION_MENU_DELETE_URL, RESET_NAVIGATION_MENU_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_NAVIGATION_MENU_REQUEST = 'FETCH_NAVIGATION_MENU_REQUEST';
export const FETCH_NAVIGATION_MENU_SUCCESS = 'FETCH_NAVIGATION_MENU_SUCCESS';
export const FETCH_NAVIGATION_MENU_FAILURE = 'FETCH_NAVIGATION_MENU_FAILURE';

export const ADD_NAVIGATION_MENU_REQUEST = 'ADD_NAVIGATION_MENU_REQUEST';
export const ADD_NAVIGATION_MENU_SUCCESS = 'ADD_NAVIGATION_MENU_SUCCESS';
export const ADD_NAVIGATION_MENU_FAILURE = 'ADD_NAVIGATION_MENU_FAILURE';

export const DELETE_NAVIGATION_MENU_REQUEST = 'DELETE_NAVIGATION_MENU_REQUEST';
export const DELETE_NAVIGATION_MENU_SUCCESS = 'DELETE_NAVIGATION_MENU_SUCCESS';
export const DELETE_NAVIGATION_MENU_FAILURE = 'DELETE_NAVIGATION_MENU_FAILURE';

export const MULTIPLE_DELETE_NAVIGATION_MENU_SUCCESS = 'MULTIPLE_DELETE_NAVIGATION_MENU_SUCCESS';

export const UPDATE_NAVIGATION_MENU_REQUEST = 'UPDATE_NAVIGATION_MENU_REQUEST';
export const UPDATE_NAVIGATION_MENU_SUCCESS = 'UPDATE_NAVIGATION_MENU_SUCCESS';
export const UPDATE_NAVIGATION_MENU_FAILURE = 'UPDATE_NAVIGATION_MENU_FAILURE';



export const fetchNavigationMenuRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_NAVIGATION_MENU_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_NAVIGATION_MENU_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const navigationMenu = response.data;
      dispatch({
        type: FETCH_NAVIGATION_MENU_SUCCESS,
        payload: [navigationMenu],
      });
    } else {
      const navigationMenuList = response.data;
      dispatch({
        type: FETCH_NAVIGATION_MENU_SUCCESS,
        payload: navigationMenuList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_NAVIGATION_MENU_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchNavigationMenuSuccess = (navigationMenu) => ({
    type: FETCH_NAVIGATION_MENU_SUCCESS,
    payload: navigationMenu,
  });
  
  export const fetchNavigationMenuFailure = (error) => ({
    type: FETCH_NAVIGATION_MENU_FAILURE,
    payload: error,
  });
  
  export const addNavigationMenuRequest = (navigationMenu, navigate) => async (dispatch) => {
    dispatch({ type: ADD_NAVIGATION_MENU_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', navigationMenu.Name);
        formData.append('Url', navigationMenu.Url);
        if (navigationMenu.Order) {
          formData.append('Order', navigationMenu.Order);
        }
        formData.append('Type', navigationMenu.Type);     
        formData.append('HomeScreen', navigationMenu.HomeScreen);     
        formData.append('DisplayAs', navigationMenu.DisplayAs);   
          
        if (navigationMenu.MenuProducts.length > 0) {
          navigationMenu.MenuProducts.forEach((product) => {
            formData.append('MenuProducts', product);
          });
        }
    
        // Check if MenuCategories is not empty before appending
        if (navigationMenu.MenuCategories.length > 0) {
          navigationMenu.MenuCategories.forEach((category) => {
            formData.append('MenuCategories', category);
          });
        }
    
        // Check if MenuSubCategories is not empty before appending
        if (navigationMenu.MenuSubCategories.length > 0) {
          navigationMenu.MenuSubCategories.forEach((subcategory) => {
            formData.append('MenuSubCategories', subcategory);
          });
        }
         

      const response = await axios.post(ADD_NAVIGATION_MENU_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_NAVIGATION_MENU_SUCCESS, payload: [response.data] });
      navigate('/navigationMenu'); 

  } catch (error) {
    dispatch({
      type: ADD_NAVIGATION_MENU_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding NavigationMenu.")
  }
  };
  
  export const addNavigationMenuSuccess = () => ({
    type: ADD_NAVIGATION_MENU_SUCCESS,
  });
  
  export const addNavigationMenuFailure = (error) => ({
    type: ADD_NAVIGATION_MENU_FAILURE,
    payload: error,
  });


  export const deleteNavigationMenuRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_NAVIGATION_MENU_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_NAVIGATION_MENU__URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_NAVIGATION_MENU_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_NAVIGATION_MENU_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete NavigationMenu');
    }
  };

  export const deleteNavigationMenuSuccess = () => ({
    type: DELETE_NAVIGATION_MENU_SUCCESS,
  });
  
  export const deleteNavigationMenuFailure = (error) => ({
    type: DELETE_NAVIGATION_MENU_FAILURE,
    payload: error,
  });


  export const multipleDeleteNavigationMenuRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_NAVIGATION_MENU_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_NAVIGATION_MENU_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_NAVIGATION_MENU_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_NAVIGATION_MENU_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateNavigationMenuRequest = (navigationMenu, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_NAVIGATION_MENU_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', navigationMenu.Name);
        formData.append('Url', navigationMenu.Url);
        if (navigationMenu.Order) {
          formData.append('Order', navigationMenu.Order);
        }
        formData.append('Type', navigationMenu.Type);     
        formData.append('HomeScreen', navigationMenu.HomeScreen);     
        formData.append('DisplayAs', navigationMenu.DisplayAs);     
        
        
        if (navigationMenu.MenuProducts.length > 0) {
          navigationMenu.MenuProducts.forEach((product) => {
            formData.append('MenuProducts', product);
          });
        }
    
        // Check if MenuCategories is not empty before appending
        if (navigationMenu.MenuCategories.length > 0) {
          navigationMenu.MenuCategories.forEach((category) => {
            formData.append('MenuCategories', category);
          });
        }
    
        // Check if MenuSubCategories is not empty before appending
        if (navigationMenu.MenuSubCategories.length > 0) {
          navigationMenu.MenuSubCategories.forEach((subcategory) => {
            formData.append('MenuSubCategories', subcategory);
          });
        }
         

      const response = await axios.put(`${UPDATE_NAVIGATION_MENU__URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.data);
  

      dispatch({ type: UPDATE_NAVIGATION_MENU_SUCCESS, payload: [response.data] });
      navigate('/navigationMenu'); 

    } catch (error) {
      dispatch({
        type: UPDATE_NAVIGATION_MENU_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update NavigationMenu');
    }
  };
  

  
    export const ResetNavigationMenuRequest = () => async (dispatch) => {
      dispatch({ type: FETCH_NAVIGATION_MENU_REQUEST });
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post(RESET_NAVIGATION_MENU_URL, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response.data);
    
        dispatch({ type: FETCH_NAVIGATION_MENU_SUCCESS, payload: response.data });

      } catch (error) {
        console.log(error);
        dispatch({
          type: FETCH_NAVIGATION_MENU_FAILURE,
          payload: error.message,
        });
        // Handle error message if needed
      }
    };

  
  
