import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from '@react-spring/web'

import {
  fetchCountRequest,
} from "../redux_store/actions/dashboardActions";

const AnimatedCounter = ({ value , className }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: value },
    config: { duration: 1000 }, // Animation duration in milliseconds
  });

  return <animated.h5 className={className} >{number.interpolate((val) => Math.floor(val))}</animated.h5>;
};

const Home = () => {
  const dispatch = useDispatch();

  const { count, loading, error } = useSelector(
    (state) => state.dashboard
  );

  
  const initialValues = {
    "posts": 0,
    "messages": 0,
    "products": 0,
    "categories": 0,
    "uniqueVisitors": 0,
    "totalVisitors": 0,
    "orders": 0,
    "enquiry": 0,
    "reviews": 0,
    "testimonials": 0,
    "carts": 0,
    "category": 0,
    "subCategory": 0,
    "coupons": 0,
    "deals": 0,
    "blog": 0,
    "customPages": 0,
    "tax": 0,
    "menu": 0,
    "users": 0,
    "newUsers": 0
};

if(count){
  initialValues.posts = count.posts;
  initialValues.products = count.products;
  initialValues.messages = count.messages;
  initialValues.categories = count.categories;
  initialValues.uniqueVisitors = count.uniqueVisitors;
  initialValues.totalVisitors = count.totalVisitors;
  initialValues.orders = count.orders;
  initialValues.enquiry = count.enquiry;
  initialValues.reviews = count.reviews;
  initialValues.testimonials = count.testimonials;
  initialValues.carts = count.carts;
  initialValues.category = count.category;
  initialValues.subCategory = count.subCategory;
  initialValues.coupons = count.coupons;
  initialValues.deals = count.deals;
  initialValues.blog = count.blog;
  initialValues.customPages = count.customPages;
  initialValues.tax = count.tax ;
  initialValues.menu = count.menu;
  initialValues.users = count.users;
  initialValues.newUsers = count.newUsers;
}

  useEffect(() => {
    dispatch(fetchCountRequest());
  }, [dispatch]);

  console.log(count);

  return (
    <>
    <div className='row row-cols-md-5 py-4'>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-primary-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-primary mb-3" style={{textTransform: 'uppercase'}}>
                    Unique Visitors
                  </p>
                  <AnimatedCounter value={initialValues.uniqueVisitors} className="fw-semibold text-primary mb-0"/>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-warning-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-warning mb-3" style={{textTransform: 'uppercase'}}>
                    Total Visitors
                  </p>
                  <AnimatedCounter value={initialValues.totalVisitors} className="fw-semibold text-warning mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-info-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-info mb-3" style={{textTransform: 'uppercase'}}>
                    Total Orders
                  </p>
                  <AnimatedCounter value={initialValues.orders} className="fw-semibold text-info mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-danger-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-danger mb-3" style={{textTransform: 'uppercase'}}>
                    Enquiries
                  </p>
                  <AnimatedCounter value={initialValues.enquiry} className="fw-semibold text-danger mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-success-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-success mb-3" style={{textTransform: 'uppercase'}}>
                    Reviews
                  </p>
                  <AnimatedCounter value={initialValues.reviews} className="fw-semibold text-success mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-primary-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-primary mb-3" style={{textTransform: 'uppercase'}}>
                    Testimonials
                  </p>
                  <AnimatedCounter value={initialValues.testimonials} className="fw-semibold text-primary mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-warning-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-warning mb-3" style={{textTransform: 'uppercase'}}>
                    Inventory
                  </p>
                  <AnimatedCounter value={0} className="fw-semibold text-warning mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-info-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-info mb-3" style={{textTransform: 'uppercase'}}>
                    Abandoned Carts
                  </p>
                  <AnimatedCounter value={initialValues.carts} className="fw-semibold text-info mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-danger-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-danger mb-3" style={{textTransform: 'uppercase'}}>
                    Categories
                  </p>
                  <AnimatedCounter value={initialValues.categories} className="fw-semibold text-danger mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-success-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-success mb-3" style={{textTransform: 'uppercase'}}>
                    Sub Categories
                  </p>
                  <AnimatedCounter value={initialValues.subCategory} className="fw-semibold text-success mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-primary-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-primary mb-3" style={{textTransform: 'uppercase'}}>
                    Coupons
                  </p>
                  <AnimatedCounter value={initialValues.coupons} className="fw-semibold text-primary mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-warning-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-warning mb-3" style={{textTransform: 'uppercase'}}>
                    Offers
                  </p>
                    <AnimatedCounter value={initialValues.deals} className="fw-semibold text-warning mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-info-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-info mb-3" style={{textTransform: 'uppercase'}}>
                    Blog
                  </p>
                  <AnimatedCounter value={initialValues.blog} className="fw-semibold text-info mb-0" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-danger-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-danger mb-3" style={{textTransform: 'uppercase'}}>
                    Custom Pages
                  </p>
                  <AnimatedCounter value={initialValues.customPages} className="fw-semibold text-danger mb-0" />

                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-success-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-success mb-3" style={{textTransform: 'uppercase'}}>
                    Customers
                  </p>
                  <AnimatedCounter value={initialValues.users} className="fw-semibold text-success mb-0" />

                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='col'>
        <div className="item">
            <div className="card border-0 zoom-in bg-primary-subtle shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-primary mb-3" style={{textTransform: 'uppercase'}}>
                    Navigation Menu
                  </p>
                  <AnimatedCounter value={initialValues.menu} className="fw-semibold text-primary mb-0" />

                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  )
}

export default Home