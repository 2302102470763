import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-form-wizard-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as Yup from "yup";
import { BASE_URL } from "../../apis/url_constants";

import { GET_ORDER_URL } from "../../apis/url_constants";

const OrderView = () => {
    const { id } = useParams();
    const editMode = Boolean(id);

    const location = useLocation();
    const viewMode = location.pathname.includes("/View/");
    const [order, setOrder] = useState(null);


    useEffect(() => {

        const fetchData = async () => {
            try {
                let endpoint = GET_ORDER_URL;
                const token = localStorage.getItem('token');
                const response = await axios.get(`${endpoint}/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setOrder(response.data);
                console.log(response.data);

            } catch (error) {
                // Handle error if needed
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);




    //const handleDelete = (ImageId) => {
    //    dispatch(deleteProductImageRequest(ImageId));
    //};

    //const handleBannerDelete = (ImageId) => {
    //    dispatch(deleteProductBannerImageRequest(ImageId));
    //};

    if (!order) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return (

        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h3>
                            <span>Order</span>
                        </h3>
                    </div>
                    <div className="card-body pb-0">

                    
                        <div>
                            <p>Event Type: {order.eventType}</p>
                            <p>Event Date: {new Date(order.eventDate).toLocaleDateString()}</p>
                            <p>Delivery Date: {new Date(order.deliveryDate).toLocaleDateString()}</p>
                            <p>Payment Status: {order.paymentStatus}</p>
                            <p>Amount: ${order.amount}</p>
                        </div>

                        <div>
                            <h4>Address:</h4>
                            <p>{order.address.name}</p>
                            <p>{order.address.mainAddress}</p>
                            <p>{order.address.landmark} {order.address.city}, {order.address.state} - {order.address.pinCode}</p>
                            <p>Mobile: {order.address.mobileNumber} {order.address.alternatePhone}</p>
                        </div>

                        <h4>Ordered Products:</h4>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.orderProducts.map(product => (
                                    <tr key={product.id}>
                                        <td><img src={`${BASE_URL}/${product.product.image}`} alt={product.product.name} style={{ width: '50px', height: 'auto' }} /></td>
                                        <td>{product.name}</td>
                                        <td>{product.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderView;
