import { React, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteOrderRequest, fetchOrderRequest } from '../../redux_store/actions/orderActions';

const Order = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { order, loading, error } = useSelector(
        (state) => state.order
      );

    useEffect(() => {
        dispatch(fetchOrderRequest());
      }, [dispatch]);

     

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteOrderRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };
      
     
    
      const handleEdit = (row) => {
        // Implement your edit logic here
        console.log('Edit row:', row);
      };
    
      const handleView = (row) => {
        // Implement your view logic here
        console.log('View row:', row);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };

      
      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = order.length > 0 ? Object.keys(order[0]).map((key) => {

        if (key === 'product') {
          return {
            name: 'Product',
            selector: (row) => (row.product ? row.product.name : ''),
            sortable: true,
          };
        }
        if(key === 'user')
          {
            return {
              name: "User",
              selector: (row) => (row.user ? row.user.fullName : ''),
              sortable: true,
            }
          }

      
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

    const columnsToRemove = ['Id', 'CreatedAt', 'LastModified', 'OrderProducts', 'Address', 'OrderShipment', 'Invoice', 'UserId', "Products", "AddressId"];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                {/* <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
                <Link to={`/addOrder/${row.id}`}><i className='ti ti-pencil text-info fs-6 px-2'></i></Link> */}
                <Link to={`/addOrder/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link>
              </>
            ),
            sortable: false,
          };

          const modifiedColumns = [actionsColumn , ...filteredColumns];


          console.log(modifiedColumns)
  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Product Order</h3>
                    <div className='d-flex align-items-center'>
                    <Link to="/addOrder" className='btn btn-primary ms-2'>Add Order</Link>
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={order}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default Order