import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT , LOGIN_SUCCESS_NOTADMIN } from '../actions/authActions';

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  user: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: true,
        user: action.payload,
        error: null,
      };
    case LOGIN_SUCCESS_NOTADMIN:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: false,
        user: action.payload,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
