import { React, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbandonedWishlistRequest } from '../../redux_store/actions/abandonedWishlistActions';
import { Link } from 'react-router-dom';


const AbandonedWishlist = () => {

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { abandonedWishlist, loading, error } = useSelector(
        (state) => state.abandonedWishlist
      );

      useEffect(() => {
        dispatch(fetchAbandonedWishlistRequest());
      }, [dispatch]);

      
      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };

      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = abandonedWishlist.length > 0 ? Object.keys(abandonedWishlist[0]).map((key) => {

        if (key === 'product') {
          return {
            name: 'Product',
            selector: (row) => (row.product ? row.product.name : ''),
            sortable: true,
          };
        }

        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id', 'CreatedAt' , 'LastModified'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <Link to={`/addAbandonedWishlist/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link>
              </>
            ),
            sortable: false,
          };

          const modifiedColumns = [actionsColumn , ...filteredColumns];

          console.log(modifiedColumns)
  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>AbandonedWishlist</h3>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={abandonedWishlist}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                </div>
            </div>
        </div>
            
    </>
  )
}

export default AbandonedWishlist